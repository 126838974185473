<template>
  <div class="resourceDetail">
    <!-- <van-nav-bar
      title="应急资源"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar> -->
    <div class="con">
      <div class="left">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          :class="['item', currentClick == index ? 'active' : '']"
          @click="clickHandle(index, item.value)"
        >
          {{ item.label || "--" }}
        </div>
      </div>
      <div class="right">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            :error.sync="loadError"
            error-text="加载失败，请稍后再试！"
            @load="onLoad"
          >
            <div v-for="(item, index) in list" :key="index" class="block">
              <div class="top">
                <div class="topleft">
                  <div class="type overHidden">
                    {{ item.materialTypeName || "--" }}
                  </div>
                  <div class="name overHidden">
                    {{ item.materialName || "--" }}
                  </div>
                </div>
                <span class="rightsign">{{
                  item.materialStatusName || "--"
                }}</span>
              </div>
              <div class="middle">
                <div class="middleitem">
                  存储数量：{{ dataFormat(item.materialNum) }}
                </div>
                <div class="middleitem">
                  使用年限：{{ addUnit(item.lifeSpan) }}
                </div>
              </div>
              <div class="bottom" @click="look(item)">
                <div class="bottomleft">查看详情</div>
                <van-icon name="arrow" />
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <van-action-sheet v-model="show" title="应急物资">
      <div class="detailcontent">
        <div class="detailtop">
          <div class="type overHidden">{{ form.materialTypeName || "--" }}</div>
          <div class="name overHidden">{{ form.materialName || "--" }}</div>
          <span class="rightsign">{{ form.materialStatusName || "--" }}</span>
        </div>
        <div class="detailcon">
          <div class="detailconitem">
            <div class="detailconitemlabel">出厂日期：</div>
            <div class="detailconitemvalue">{{ form.produceDate || "--" }}</div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">使用期限：</div>
            <div class="detailconitemvalue">{{ addUnit(form.lifeSpan) }}</div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">存储数量：</div>
            <div class="detailconitemvalue">
              {{ dataFormat(form.materialNum) }}
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">所属单位：</div>
            <div class="detailconitemvalue">{{ form.orgName || "--" }}</div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">责任人姓名：</div>
            <div class="detailconitemvalue">
              {{ form.headPersonName || "--" }}
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">责任人电话：</div>
            <div class="detailconitemphone">
              <a :href="`tel:${form.headPersonMobile}`" @click.stop="() => {}">
                {{ form.headPersonMobile || "--" }}</a
              >
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">用途：</div>
            <div class="detailconitemvalue">
              {{ form.useWaysName || "--" }}
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">安装/存储位置：</div>
            <div class="detailconitemvalue">
              {{ form.poiName || "--" }}
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">有效期：</div>
            <div class="detailconitemvalue">
              {{ changeTime(form.produceDate, form.lifeSpan) }}
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import api from "./api";
import { mapState } from "vuex";

export default {
  name: "MaterialsDetail",
  data() {
    return {
      show: false,
      typeList: [],
      list: [],
      total: 0,
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      query: {
        page: 1,
        size: 10,
        materialTypeCode: "",
        orgCode: ""
      },
      form: {},
      currentClick: 0,
      typequery: { types: "materialType" }
    };
  },
  created() {
    // this.query.orgCode = this.$route?.params?.orgCode;
    this.query.orgCode = this.userInfo.orgCode;
    this.getMaterialType(this.typequery);
    this.onRefresh();
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    addUnit(val) {
      return val ? val + "月" : "--";
    },
    dataFormat(val) {
      return val ?? "--";
    },
    changeTime(a, b) {
      return b
        ? dayjs(a)
            .add(b, "M")
            .format("YYYY-MM-DD")
        : "--";
    },
    onRefresh() {
      this.query.page = 1;
      this.pageMaterial(this.query);
    },
    onLoad() {
      this.pageMaterial(this.query);
    },
    getMaterialType(data) {
      api.getMaterialType(data).then(res => {
        this.mapMaterialType2List(res);
        this.typeList.unshift({ label: "全部", value: "" });
      });
    },
    onClickLeft() {
      history.go(-1);
    },
    look(data) {
      this.show = true;
      this.form = data;
    },
    clickHandle(data, val) {
      this.currentClick = data;
      this.query.materialTypeCode = val;
      this.query.page = 1;
      this.pageMaterial(this.query);
    },
    pageMaterial(data) {
      console.log(this.query);
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      api
        .pageMaterial(data)
        .then(res => {
          console.log(res);
          if (res.list) {
            this.loadError = false;
            this.loading = false;
            if (this.query.page == 1) {
              this.list = res.list;
            } else {
              this.list = this.list.concat(res.list);
            }
            if (this.list.length >= res.total) {
              this.finished = true;
            } else {
              this.query.page++;
            }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
          this.loadError = true;
        });
    },
    mapMaterialType2List(tree) {
      if (tree.length > 0) {
        tree.forEach(item => {
          this.typeList.push(item);
          if (item.children?.length > 0) {
            this.mapMaterialType2List(item.children);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.resourceDetail {
  div {
    box-sizing: border-box;
  }
  .detailcontent {
    padding: 16px;
    .detailtop {
      display: flex;
      .type {
        max-width: 60px;
        padding: 2px;
        background: #3e8dfe;
        box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
        border-radius: 2px 0px 0px 2px;
        color: #ffffff;
        font-size: 12px;
        margin-right: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .name {
        max-width: 214px;
        font-size: 16px;
        color: #333743;
        font-weight: 600;
        margin-right: 12px;
      }
      .rightsign {
        font-size: 12px;
        padding: 2px;
        max-height: 14px;
        color: #3d8cfe;
        background: rgba(65, 143, 253, 0.2);
        border-radius: 2px;
      }
    }
    .detailcon {
      .detailconitem {
        display: flex;
        margin: 12px 0;
        font-size: 14px;
        .detailconitemlabel {
          color: #3b4664;
        }
        .detailconitemvalue {
          color: #3b4664;
        }
        .detailconitemphone {
          > a {
            color: #3e8dfe;
          }
          color: #3e8dfe;
        }
      }
    }
  }
  .con {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 58px);
    background: rgba(247, 248, 250, 1);
    .left {
      width: 21.3%;
      text-align: center;
      height: 100%;
      overflow: auto;
      .item {
        width: 100%;
        padding: 20px 10px;
        display: flex;
        justify-content: center;
        line-height: 20px;
        align-items: center;
        background: #fff;
        font-size: 14px;
        color: #7d7e80;
      }
      .active {
        color: #333743;
        background: #f2f3f5;
        box-shadow: 4px 0px 8px 0px rgba(177, 200, 243, 0.15);
      }
    }
    .right {
      width: 78%;
      height: 100%;
      overflow: auto;
      padding: 10px;
      .block {
        padding: 12px 10px;
        background: #fff;
        margin-bottom: 10px;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .topleft {
            display: flex;
            align-items: center;
            .type {
              max-width: 60px;
              padding: 4px 2px;
              background: #3e8dfe;
              box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
              border-radius: 2px 0px 0px 2px;
              color: #ffffff;
              font-size: 12px;
              margin-right: 4px;
            }
            .name {
              max-width: 130px;
              font-size: 16px;
              color: #333743;
              font-weight: 600;
            }
          }
          .rightsign {
            font-size: 12px;
            padding: 2px;
            color: #3d8cfe;
            background: rgba(65, 143, 253, 0.2);
            border-radius: 2px;
          }
        }
        .middle {
          background: rgba(65, 143, 253, 0.05);
          box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
          border-radius: 2px;
          padding: 10px 12px;
          line-height: 26px;
          .middleitem {
            font-size: 14px;
            color: rgba(100, 116, 144, 0.7);
            text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
            font-weight: 400;
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          padding-top: 24px;
          .bottomleft {
            color: #418ffd;
            font-size: 14px;
            text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
          }
        }
      }
    }
  }
  .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar .van-nav-bar__title {
    color: #fff;
    font-size: 16px;
  }
  .van-icon-arrow:before {
    color: #969799;
  }
  .van-action-sheet__header {
    position: relative;
  }
  .van-action-sheet__header::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
